<template>
  <div
    class="input"
    :class="{
      'has-text': !!value,
      'input--25': w25,
      'input--75': w75,
      'input--50': w50,
      invalid: !valid,
      'input--range': type === 'range',
    }"
    :data-testid="name"
    :id="name"
    input-wrapper
  >
    <div class="input__wrapper" :class="{ 'pt-6': type === 'range' }">
      <textarea
        v-if="type === 'textarea'"
        :rows="5"
        :id="name"
        v-model="model"
        :required="required"
        :disabled="disabled"
        :name="name"
        class="input__textarea"
        :class="{ 'input__textarea--small': small }"
        :type="inputType"
        @focus="inputFocus"
        @blur="inputBlur"
        ref="textarea"
        :valid="valid"
      />
      <input
        v-else
        :id="name"
        v-model="model"
        :required="required"
        :disabled="disabled"
        :name="name"
        class="input__input"
        :class="[
          { 'input--is-password': isPassword, 'input__input--small': small },
          type === 'range' ? 'input__input--range' : '',
        ]"
        :type="inputType"
        @focus="inputFocus"
        @blur="inputBlur"
        ref="input"
        :min="type === 'range' ? min : null"
        :max="type === 'range' ? max : null"
        :step="type === 'range' ? step : null"
        :valid="valid"
      />
      <label
        v-if="label"
        class="input__label"
        :class="{ 'input__label--focus': isFocus }"
        :for="name"
      >
        <!-- @slot Custom input label -->
        <slot name="label" v-bind="{ label }">{{ label }}</slot>
      </label>
      <div
        v-if="isPassword"
        class="input__password"
        @click="switchVisibilityPassword"
      >
        <div v-if="isPasswordVisible" class="icon-eye-strike"></div>
        <div v-else class="icon-eye"></div>
      </div>

      <div
        v-if="type === 'range' && showRangeValue"
        class="range-value"
        :style="{
          left: rangePosition + '%',
          transform: 'translateX(-' + rangePosition + '%)',
        }"
      >
        {{ value ? value : (max - min) / 2 }}
      </div>
    </div>
    <div v-if="!valid" class="input__error-message">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  /**
   * Current input value (`v-model`)
   */
  value: {
    type: [String, Number, Object, Array],
    default: "",
  },
  /**
   * Form input label
   */
  label: {
    type: String,
    default: "",
  },
  /**
   * Form input name
   */
  name: {
    type: String,
    default: "",
  },
  /**
   * Form input type
   */
  type: {
    type: String,
    default: "text",
  },
  /**
   * Validate value of form input
   */

  number: {
    type: Boolean,
    default: false,
  },
  /**
   * Error message value of form input. It will be appeared if `valid` is `true`.
   */
  errorMessage: {
    type: String,
    default: "",
  },
  /**
   * Native input required attribute
   */
  required: {
    type: Boolean,
    default: false,
    description: "Native input required attribute",
  },
  /**
   * Native input disabled attribute
   */
  disabled: {
    type: Boolean,
    default: false,
    description: "Native input disabled attribute",
  },
  /**
   * Status of show password icon display
   */
  hasShowPassword: {
    type: Boolean,
    default: true,
  },
  showRangeValue: {
    type: Boolean,
    default: true,
  },
  small: {
    type: Boolean,
    default: false,
  },
  w25: {
    type: Boolean,
    default: false,
  },
  w50: {
    type: Boolean,
    default: false,
  },
  w75: {
    type: Boolean,
    default: false,
  },
  min: {
    type: [Number, null],
    default: 0,
  },
  max: {
    type: [Number, null],
    default: 100,
  },
  step: {
    type: [Number, null],
    default: 0,
  },
});

const isPasswordVisible = ref(false);
const inputType = toRef(props.type);
const isNumberTypeSafari = ref(false);
const valid = ref(true);

const component = getCurrentInstance();

const emit = defineEmits(["input", "focus", "blur"]);
const model = defineModel({ default: "" });
const isFocus = ref(false);

const isPassword = computed(() => {
  return props.type === "password" && props.hasShowPassword;
});
const rangePosition = computed(() => {
  if (props.type === "range" && props.value) {
    return (100 / (props.max - props.min)) * props.value;
  } else {
    return 50;
  }
});
watch(model, (val) => {
  if ((val && val.length) || (val && typeof val === "number" && val > 0)) {
    isFocus.value = true;
  } else {
    isFocus.value = false;
  }
});
// watch(
//   () => props.value,
//   (value) => {
//     if (!isNumberTypeSafari.value) return;
//     if (isNaN(value)) {
//       emit("input");
//     }
//   }
// );
onMounted(() => {
  inputBlur();
});
onUpdated(() => {});

const switchVisibilityPassword = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
  inputType.value = isPasswordVisible.value ? "text" : "password";
};
const inputFocus = () => {
  emit("focus");
  isFocus.value = true;
};
const inputBlur = () => {
  emit("blur");
  if (!model.value) {
    isFocus.value = false;
  } else {
    isFocus.value = true;
  }
};
</script>

<style lang="scss">
.input {
  display: block;
  margin-bottom: $spacing-xs;
  // max-width: $xs;

  &--25 {
    @media (min-width: $md) {
      width: 25%;
    }
  }

  &--75 {
    @media (min-width: $md) {
      width: 75%;
    }
  }

  &--50 {
    @media (min-width: $md) {
      width: 50%;
    }
  }

  &--range {
    height: 36px;
  }
}

.input__wrapper {
  position: relative;
}

.input__input,
.input__textarea {
  padding: 14px $spacing-md;

  border-width: 2px;
  border-color: $color-black;
  border-radius: $radius-small;
  width: 100%;
  background: $color-white;

  &--small {
    padding: $spacing-xxxs $spacing-md;
  }

  &:disabled {
    opacity: 0.4;
  }

  &[type="search"] {
    -webkit-appearance: none !important;
    // background: transparent !important;
  }

  .invalid & {
    border-color: $color-red;
    background: lighten($color-red, 20%);
  }
}

@mixin track() {
  background-color: $color-green;
  border-radius: $radius-small;
  height: 0.25rem;
}

@mixin thumb() {
  background: $color-white;
  border: 2px solid $color-black;
  border-radius: 9999px;
  height: 20px;
  width: 20px;

  transform: translateY(-8px);
  display: block;
  -webkit-appearance: none;
}

.input__input--range {
  -webkit-appearance: none;
  border-style: none;
  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent;
  line-height: 1;

  &::-webkit-slider-runnable-track {
    @include track;
  }

  &::-moz-range-track {
    @include track;
  }

  &::-ms-track {
    @include track;
  }

  &::-webkit-slider-thumb {
    @include thumb;
  }

  &::-moz-range-thumb {
    @include thumb;
    transform: translateY(-0px);
  }

  &::-ms-thumb {
    @include thumb;
  }
}

.input__label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: $spacing-md;
  transition: all 0.3s ease-in-out;
  z-index: 0;
  color: $color-black;
  pointer-events: none;
  color: $color-dark-gray;

  &--focus {
    font-size: $text-xxs;
    top: -0.6rem;
    transform: none;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 50%
    );
    color: $color-black;
    padding: 3px;
  }

  .input__textarea + &:not(.input__label--focus) {
    top: 1rem;
    transform: none;
  }
}

.input__password {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.range-value {
  font-size: $text-xs;
  position: absolute;
  left: 0;
  top: 0;
  // transform: translateX(-50%);
  width: 1.5rem;
  text-align: center;
  transition: all 0.05s linear;
}

input[data-com-onepassword-filled] {
  background-color: transparent !important;
}

.input-combi {
  display: flex;
  margin: 0 -0.25rem;

  .input {
    padding: 0 0.25rem;
  }
}
</style>
